import React from 'react'
import { Layout, ContactBanner, Footer, Section, SEO } from '../../components'
import { useTranslation } from 'react-i18next'

const Fertilizers = () => {
  const { t } = useTranslation()
  return (
    <Layout>
      <SEO title={t('titlePages:fertilizers')} />
      <Section title='fertilizer:title' text='fertilizer:main-text' />
      <ContactBanner />
      <Footer />
    </Layout>
  )
}
export default Fertilizers
